import React from 'react';

const DottedLine = () => {
  return (
    <svg
      width="93"
      height="10"
      viewBox="0 0 93 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.919922 4.99995C0.919922 2.62513 2.8451 0.699951 5.21992 0.699951H12.0999C14.4747 0.699951 16.3999 2.62513 16.3999 4.99995C16.3999 7.37478 14.4747 9.29995 12.0999 9.29995H5.21992C2.8451 9.29995 0.919922 7.37478 0.919922 4.99995ZM21.5599 4.99995C21.5599 2.62513 23.4851 0.699951 25.8599 0.699951H39.6199C41.9947 0.699951 43.9199 2.62513 43.9199 4.99995C43.9199 7.37478 41.9947 9.29995 39.6199 9.29995H25.8599C23.4851 9.29995 21.5599 7.37478 21.5599 4.99995ZM49.0799 4.99995C49.0799 2.62513 51.0051 0.699951 53.3799 0.699951H67.1399C69.5147 0.699951 71.4399 2.62513 71.4399 4.99995C71.4399 7.37478 69.5147 9.29995 67.1399 9.29995H53.3799C51.0051 9.29995 49.0799 7.37478 49.0799 4.99995ZM76.5999 4.99995C76.5999 2.62513 78.5251 0.699951 80.8999 0.699951H87.7799C90.1547 0.699951 92.0799 2.62513 92.0799 4.99995C92.0799 7.37478 90.1547 9.29995 87.7799 9.29995H80.8999C78.5251 9.29995 76.5999 7.37478 76.5999 4.99995Z"
        // fill="#5557FE"
      />
    </svg>
  );
};

export default DottedLine;
