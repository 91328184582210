import React from 'react';
import OnboardingForm from './OnboardingForm';
import './styles.scss';

export default function Onboarding() {
  return (
    <div>
      <OnboardingForm />
    </div>
  );
}
